<script setup lang="ts">
const { title, description } = defineProps<{
  background?: 'primary'
  title?: string
  description?: string
  image?: string
  noContainer?: boolean
}>()

const rootRef = useTemplateRef('appSection')
const targetIsVisible = ref(false)
const defaultSlotIsVisible = ref(false)

useIntersectionObserver(
  rootRef,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      targetIsVisible.value = true
      setInterval(() => {
        defaultSlotIsVisible.value = true
      }, title || description ? 600 : 0)
    }
  },
  { threshold: 0.2 },
)
</script>

<template>
  <div
    ref="appSection"
    :class="{
      'py-12 xl:py-24': Boolean(background),
      'container': !background && !noContainer,
      'bg-primary/5': background === 'primary',
      'grid grid-cols-5': image,
    }"
  >
    <div v-if="image" class="col-span-5 h-full xl:col-span-2">
      <NuxtImg
        :alt="`${title} Icon`"
        class="h-80 w-full object-cover xl:h-full"
        :class="{
          'opacity-0': !targetIsVisible,
          'animate-fade-in': targetIsVisible,
        }"
        fit="cover"
        format="avif,webp"
        height="1200"
        loading="lazy"
        quality="90"
        :src="image"
        width="1080"
      />
    </div>

    <div
      class="space-y-12 xl:space-y-16"
      :class="{
        'container': background && !noContainer,
        'col-span-5 border border-l-0 border-black/5 bg-primary/5 p-8 xl:col-span-3 xl:p-16 xl:pl-24': image,
      }"
    >
      <div
        v-if="title || description"
        class="space-y-4"
        :class="{ 'text-center': !image, 'container': noContainer }"
      >
        <h2
          v-if="title"
          class="h2"
          :class="{
            'opacity-0': !targetIsVisible,
            'animate-fade-in': targetIsVisible,
          }"
        >
          {{ title }}
        </h2>

        <p
          v-if="description"
          class="mx-auto max-w-4xl text-muted-foreground"
          :class="{
            'opacity-0': !targetIsVisible,
            'animate-fade-in animation-delay-300': targetIsVisible,
          }"
        >
          {{ description }}
        </p>
      </div>

      <div
        :class="{
          'opacity-0': !targetIsVisible,
          'animate-fade-in animation-delay-600': targetIsVisible && (title || description),
          'animate-fade-in': targetIsVisible && !title && !description,
        }"
      >
        <slot :default-slot-is-visible />
      </div>
    </div>
  </div>
</template>
